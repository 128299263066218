@import "./form.scss";

.dropzone{
    border: #9d92e6 2px dashed;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    background-color: transparent;
    outline: none;
    color: #bdbdbd;
    padding: 40px;
    margin: 0 40px;
    text-align: center;
    transition: border 0.24s ease-in-out;
}


.sidebar-heading{
    a{
        text-decoration: none;
        color: #f3f4f7;
    }
}

.custom-react-tooltip {
    padding: 5px !important;
    border-radius: 6px !important;
}